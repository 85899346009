html {
  background-color: black;
  font-family: 'Bodoni Moda', serif;
  --color-primary: white;
  
  overflow: auto;
  scroll-snap-type: y mandatory;
  scroll-snap-stop: always;
}

#root {
  color: var(--color-primary);
  height: 100vh;
}

.color-primary {
  color: var(--color-primary) !important;
}

h1, h2, h3, h4, h5, h6, a {
  color: var(--color-primary);
}

.custom-shadow {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .9);
}

.bg-translucent {
  background-color: rgba(26, 26, 26, 0.9)
}

#praise-column div:nth-child(2) {
  height: 100%;
  display: flex;
  align-items: center;
}